<template>
	<div class="regjjr">
		<blue-title>完善经纪人信息</blue-title>
		<div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="手机号码" prop="phone">
					<el-input style="width:400px;"
						οninput="value=value.replace(/[^\d]/g,'');if(value.length > 11)value = value.slice(0, 11)"
						v-model="ruleForm.phone"></el-input>
				</el-form-item>

				<el-form-item label="真实姓名" prop="truename">
					<el-input style="width:400px;" v-model="ruleForm.truename"></el-input>
				</el-form-item>

				<el-form-item label="性别" prop="sex">
					<el-select v-model="ruleForm.sex" placeholder="请选择性别">
						<el-option label="男" value="1"></el-option>
						<el-option label="女" value="2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="出生日期" prop="birth_day">
					<el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="出生日期"
						v-model="ruleForm.birth_day" style="width: 400px;"></el-date-picker>
				</el-form-item>

				<el-form-item label="身份证号" prop="id_card">
					<el-input style="width:400px;" v-model="ruleForm.id_card"></el-input>
				</el-form-item>

				<el-form-item label="籍贯" prop="jiguan">
					<el-input style="width:400px;" v-model="ruleForm.jiguan"></el-input>
				</el-form-item>

				<el-form-item style="width:400px;" label="现居城市" prop="live_city">
					<div class="block">
						<el-cascader style="width:400px;" v-model="ruleForm.live_city" :options="chinacity"
							:props="{ value: 'id', emitPath: true }" clearable></el-cascader>
					</div>
				</el-form-item>

				<el-form-item label="邮编" prop="zip_code">
					<el-input style="width:400px;" v-model="ruleForm.zip_code"></el-input>
				</el-form-item>
				<el-form-item label="电子邮箱" prop="email">
					<el-input style="width:400px;" v-model="ruleForm.email"></el-input>
				</el-form-item>
				<el-form-item label="住址" prop="address">
					<el-input style="width:600px;" v-model="ruleForm.address"></el-input>
				</el-form-item>

				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">保存信息</el-button>
					<!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
				</el-form-item>
			</el-form>
		</div>
		<el-dialog title="提示" :lock-scroll="false" :visible.sync="dialogVisible" width="30%" @close="toUrl"
			:show-close="showclose" :before-close="handleClose">
			<span>{{ msg }}</span>
			<span slot="footer" class="dialog-footer">
				<el-button v-if="showclose" type="primary" @click="dialogVisible = false">确 定</el-button>
				<el-button v-if="returnpage" type="primary" @click="toreturnpage">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { detail, save } from '@/api/website/regjjr.js'

import BlueTitle from '../../../components/website/BlueTitle.vue';

import { chinacity } from '@/api/website/bingo'

export default {
	components: { BlueTitle },
	name: 'PcWebsiteJjrzc',

	data() {
		return {

			dialogVisible: false,
			msg: '',
			chinacity: [],
			showclose: true,
			returnpage: false,
			ruleForm: {
				phone: '',
				truename: '',
				sex: '',
				birth_day: '',
				jiguan: '',
				id_card: '',
				live_city: '',
				zip_code: '',
				email: '',
				address: ''
			},
			rules: {
				phone: [
					{ required: true, message: "请输入手机号", trigger: "blur" },
					// 这个只能验证手机号
					{ pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" }
				],
				truename: [
					{ required: true, message: '真实姓名不能为空', trigger: 'blur' }
				],
				sex: [
					{ required: true, message: '请选择性别', trigger: 'blur' }
				],
				birth_day: [
					{ required: true, message: '请选择生日', trigger: 'blur' }
				],
				id_card: [
					{ required: true, message: "请输入身份证号", trigger: "blur" },
					{ pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/, message: "请输入合法身份证号", trigger: "blur" }

				],
				email: [
					{ required: true, message: "请输入邮箱", trigger: "blur" },
					{ pattern: /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g, message: "请输入正确的邮箱", trigger: "blur" }
				],
				live_city: [
					{ required: true, message: '请选择居住城市', trigger: 'blur' }
				],
				zip_code: [
					{ required: true, message: '邮编不能为空', trigger: 'blur' }
				],
				jiguan: [
					{ required: true, message: '籍贯不能为空', trigger: 'blur' }
				],
				address: [
					{ required: true, message: '住址不能为空', trigger: 'blur' }
				],

			}
		};
	},
	created() {
		// alert('created')
	},
	mounted() {
		// alert('mounted')
		chinacity().then(res => {
			this.chinacity = res.data
		})

		this.init()
	},

	methods: {
		toreturnpage() {

			this.$router.push('/cn/list/jujian');

		},

		handleClose() {

		},
		init() {
			detail().then(res => {


				console.log('this.ruleForm', this.ruleForm)
				this.ruleForm = this.handleReg(res.data)
			})
		},
		submitForm(formName) {

			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveReg()
				} else {
					return false;
				}
			});
		},

		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		//保存
		saveReg() {
			const params = this.handleParams(this.ruleForm)
			save(params).then(res => {
				this.init()
			})
		},

		handleReg(data) {

			if (data.sex !== undefined) {
				data.sex = data.sex.toString()
			}

			if (data.area_id !== undefined) {
				data.live_city = data.area_id
			}

			if (data.examine !== undefined) {

				const examine = data.examine
				switch (examine) {
					case 0:
						this.showclose = false
						this.returnpage = true
						this.dialogVisible = true
						this.msg = '您的申请已提交等待工作人员审核，请耐心等待～'
						break;
					case 1:
						this.returnpage = false
						this.toBingo()
						break;
					case 2:
						this.returnpage = false
						this.showclose = true
						this.dialogVisible = true
						this.msg = data.reject
						break;
				}

			}

			return data
		},

		toBingo() {
			const tourl = '/cn/list/addbingo'
			this.$router.push(tourl)
		},

		//处理省市区
		handleParams(params) {
			params.province_id = params.live_city[0]
			params.city_id = params.live_city[1]
			params.area_id = params.live_city[2]
			return params
			// params.live_city
		},

		//save后跳转
		toUrl() {
			this.$rotuer.push('/cn/list/addbingo')
		}

	}

};
</script>

<style lang="scss" scoped>
.regjjr {
	width: 1200px;
	height: 798px;
	background: #FFFFFF;
}

::v-deep .el-form-item__content {
	display: flex;
}

.dialog-footer {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>




